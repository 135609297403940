import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getProducts,
  deleteProduct,
  resetProduct,
  resetAllProduct,
  selectProduct,
} from '../../actions/product';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import { formatDate } from '../../utils/formatDate';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * Renders the Products component.
 *
 *************************************************************************************/
const Products = ({
  getProducts,
  deleteProduct,
  resetProduct,
  resetAllProduct,
  selectProduct,
  pageName,
  loadPageName,
  loadPageIcon,
  product: { products, product, loading },
}) => {
  // INITIALIZATIONS AND STATES
  const navigate = useNavigate();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Products') {
      loadPageName('Products');
      loadPageIcon('fa-solid fa-microchip');
    }

    getProducts();
  }, []);

  // HANDLER: CLICK CHECKBOX
  const handleCheckboxChange = (e, productId) => {
    if (e.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, productId]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== productId));
    }
  };

  // HANDLER: CLICK CREATE
  const onClickCreate = () => {
    // Navigate to the ProductForm page
    resetProduct();
    navigate('/productForm');
  };

  // HANDLER: CLICK UPDATE
  const onClickUpdate = (id) => {
    // Set the selected product
    const selectedProduct = products.find((product) => product.id === Number(id));
    if (selectedProduct) {
      selectProduct(selectedProduct);
    }

    // Navigate to the ProductForm page
    navigate(`/productForm`);
  };

  // HANDLER: CLICK DELETE
  const onClickDelete = () => {
    // Delete each selected product by its id
    selectedCheckboxes.forEach((id) => {
      deleteProduct(id);
    });

    // Clear the list of selected checkboxes
    setSelectedCheckboxes([]);

    // Fetch the updated list of products
    getProducts();
  };

  // HANDLER: CLICK RELOAD
  const onClickReload = () => {
    setSelectedCheckboxes([]);
    resetAllProduct();
    getProducts();
  };

  // RENDER
  return (
    <section>
      {loading ? <Spinner /> : null}
      <section className='container'>
        {/*--- HEADER --->*/}
        <div className='container-header'>
          {/*--- TITLE --->*/}
          <div className='container-title'>
            <i className='fa-solid fa-microchip fa-xl text-primary' />
            <h1 className='text-primary'>Products</h1>
          </div>
          {/*--- ACTIONS --->*/}
          <div className='container-action'>
            {/*--- CREATE --->*/}
            <button
              className={
                selectedCheckboxes.length < 1
                  ? 'btn btn-primary'
                  : 'btn btn-primary btn-disabled disabled'
              }
              onClick={() => onClickCreate()}
            >
              <i className='fas fa-plus ' />
            </button>
            {/*--- EDIT --->*/}
            <button
              className={
                selectedCheckboxes.length === 1
                  ? 'btn btn-primary'
                  : 'btn btn-primary btn-disabled disabled'
              }
              onClick={() => onClickUpdate(selectedCheckboxes)}
            >
              <i className='fas fa-pencil ' />
            </button>
            {/*--- DELETE --->*/}
            <button
              className={
                selectedCheckboxes.length > 0
                  ? 'btn btn-danger'
                  : 'btn btn-danger btn-disabled disabled'
              }
              onClick={() => onClickDelete()}
            >
              <i className='fas fa-trash' />
            </button>

            {/*--- RELOAD --->*/}
            <button className={'btn btn-primary'} onClick={() => onClickReload()}>
              <i className='fas fa-rotate-right ' />
            </button>
          </div>
        </div>

        {/*--- BODY --->*/}
        <section className='container-body'>
          <div className='table'>
            <table className='table'>
              <thead>
                <tr>
                  <th>&#x2713;</th>
                  <th>Name</th>
                  <th>Prefix</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {products.map((product) => (
                  <tr key={product.id}>
                    <td>
                      <input
                        type='checkbox'
                        id={product.id}
                        onChange={(e) => handleCheckboxChange(e, product.id)}
                      />
                    </td>
                    <td>{product.name}</td>
                    <td>{product.prefix}</td>
                    <td>{formatDate(product.createdAt)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </section>
  );
};

Products.propTypes = {
  getProducts: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  resetProduct: PropTypes.func.isRequired,
  resetAllProduct: PropTypes.func.isRequired,
  selectProduct: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  product: state.product,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  getProducts,
  deleteProduct,
  resetProduct,
  resetAllProduct,
  selectProduct,
  loadPageName,
  loadPageIcon,
})(Products);
