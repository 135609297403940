import { NAVBAR_LOADPAGENAME, NAVBAR_LOADPAGEICON } from './types';

/*************************************************************************************
 * LOAD PAGE NAME
 *
 * Display page name in navbar.
 *
 *************************************************************************************/
export const loadPageName = (pageName, iconName) => (dispatch) => {
  dispatch({
    type: NAVBAR_LOADPAGENAME,
    payload: pageName,
  });
};

/*************************************************************************************
 * LOAD PAGE ICON
 *
 * Display page icon in navbar.
 *
 *************************************************************************************/
export const loadPageIcon = (iconName) => (dispatch) => {
  dispatch({
    type: NAVBAR_LOADPAGEICON,
    payload: iconName,
  });
};
