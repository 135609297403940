import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTestResultDetail, updateTestResultDetail } from '../../actions/testResultDetail';
import { useNavigate } from 'react-router-dom';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * Renders a form for creating or updating a testResultDetail.
 *
 *************************************************************************************/
const TestResultDetailForm = ({
  createTestResultDetail,
  updateTestResultDetail,
  loadPageName,
  loadPageIcon,
  testResult: { testResult },
  testResultDetail: { testResultDetails, testResultDetail, loading },
  pageName,
}) => {
  // INITIALIZATIONS AND STATES
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    TestType: testResultDetail ? testResultDetail.TestType : '',
    TestTypeDescription: testResultDetail ? testResultDetail.TestTypeDescription : '',
    Status: testResultDetail ? testResultDetail.Status : '',
    ElapseTime: testResultDetail ? testResultDetail.ElapseTime : '',
    Details: testResultDetail ? testResultDetail.Details : '',
    Passed: testResultDetail ? testResultDetail.Passed : '',
  });

  const { TestType, TestTypeDescription, Status, ElapseTime, Details, Passed } = formData;

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Test Results > Test Result Details > Test Result Detail') {
      loadPageName('Test Results > Test Result Details > Test Result Detail');
      loadPageIcon('fa-check-double');
    }
  }, [testResultDetail, formData]);

  // HANDLER: FIELD CHANGE
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // HANDLER: CLICK SAVE
  const onClickSave = (e) => {
    e.preventDefault();

    // Create or update the testResultDetail
    if (!testResultDetail) {
      createTestResultDetail(formData).then((data) => {
        if (data) {
          navigate(`/testResultDetails`);
        }
      });
    } else {
      updateTestResultDetail(testResultDetail, formData).then((data) => {
        if (data) {
          navigate(`/testResultDetails`);
        }
      });
    }
  };

  // HANDLER: CLICK BACK
  const onClickBack = () => {
    navigate(`/testResultDetails`);
  };

  // RENDER
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <section className='container'>
            {/*--- HEADER --->*/}
            <div className='container-header'>
              {/*--- TITLE ---*/}
              <div className='container-title'>
                <i className='fas fa-check-double fa-xl text-primary' />
                <h1 className='text-primary'>Test Result Detail</h1>
              </div>
              {/*--- ACTIONS ---*/}
              <div className='container-action'>
                {/*--- BACK --->*/}
                <button className={'btn btn-primary'} onClick={() => onClickBack()}>
                  <i className='fas fa-arrow-left' />
                </button>
              </div>
            </div>

            {/*--- BODY --->*/}
            <section className='container-body'>
              <form className='form' onSubmit={onClickSave}>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='TestType'
                    name='TestType'
                    value={TestType}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='TestTypeDescription'
                    name='TestTypeDescription'
                    value={TestTypeDescription}
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Status'
                    name='Status'
                    value={Status}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='ElapseTime'
                    name='ElapseTime'
                    value={ElapseTime}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Details'
                    name='Details'
                    value={Details}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Passed'
                    name='Passed'
                    value={Passed}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <input type='submit' className='btn btn-primary' value='Save' />
              </form>
            </section>
          </section>
        </Fragment>
      )}
    </section>
  );
};

TestResultDetailForm.propTypes = {
  createTestResultDetail: PropTypes.func.isRequired,
  updateTestResultDetail: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  testResultDetail: PropTypes.object.isRequired,
  testResult: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  testResultDetail: state.testResultDetail,
  testResult: state.testResult,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  createTestResultDetail,
  updateTestResultDetail,
  loadPageName,
  loadPageIcon,
})(TestResultDetailForm);
