import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTestResultsWithFilter,
  deleteTestResult,
  resetTestResult,
  selectTestResult,
} from '../../actions/testResult';
import { getProducts } from '../../actions/product';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';
import { oleAutomationDateToLocal, formatTime } from '../../utils/formatDate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

/*************************************************************************************
 * Renders the TestResults component.
 *
 *************************************************************************************/
const TestResults = ({
  getProducts,
  getTestResultsWithFilter,
  deleteTestResult,
  resetTestResult,
  selectTestResult,
  loadPageName,
  loadPageIcon,
  testResult: { testResults, testResult, count },
  loading,
  pageName,
}) => {
  // INITIALIZATIONS AND STATES
  const navigate = useNavigate();

  const [productDropbox, setProductIdDropbox] = useState([
    {
      value: 'All',
      label: 'All Products',
    },
  ]);

  const overallTestStatusDropbox = [
    { value: 'All', label: 'All Overall Test Status' },
    { value: 'Passed', label: 'Passed' },
    { value: 'Failed', label: 'Failed' },
  ];

  const [filterData, setFilterData] = useState({
    SerialNumber: '',
    StartDateTime: '',
    EndDateTime: '',
    OverallTestStatus: 'All',
    ProductId: 'All',
    Limit: 10,
    SelectedColumn: 'StartTime',
    PageNumber: 1,
  });

  const [serialNumber, setSerialNumber] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [selectedOverallTestStatus, setOverallTestStatus] = useState(overallTestStatusDropbox[0]);
  const [selectedStartDateTime, setStartDateTime] = useState(null);
  const [selectedEndDateTime, setEndDateTime] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState('StartTime');
  const [selectedColumnOrder, setSelectedColumnOrder] = useState('DESC');
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageRange, setSelectedPageRange] = useState(`0 - ${filterData.Limit}`);
  const [selectedProductId, setProductId] = useState(productDropbox[0]);
  const [prvButtonEnabled, setPrvButtonEnabled] = useState(0);
  const [nxtButtonEnabled, setNxtButtonEnabled] = useState(1);

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Test Results') {
      loadPageName('Test Results');
      loadPageIcon('fa-check-double');
    }

    getTestResultsWithFilter(filterData);
    refreshSelectedPageRange();

    getProducts().then((products) => {
      if (products !== null) {
        getDropboxProducts(products);
      }
    });
  }, [filterData, count]);

  // HANDLER: CLICK CHECKBOX
  const handleCheckboxChange = (e, testResultId) => {
    if (e.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, testResultId]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== testResultId));
    }
  };

  // HANDLER: CLICK SERIAL NUMBER LINK
  const onClickSerialNumberLink = (e, testResultId) => {
    e.preventDefault();

    // Set the selected testResult
    const selectedTestResult = testResults.find((testResult) => testResult.id === testResultId);
    if (selectedTestResult) {
      selectTestResult(selectedTestResult);
    }

    navigate(`/testResultDetails`);
  };

  // HANDLER: SERIAL NUMBER CHANGE
  const onChangeSerialNumber = (event) => {
    setSerialNumber(event.target.value);

    setFilterData({ ...filterData, SerialNumber: event.target.value });
  };

  // HANDLER: START DATE TIME CHANGE
  const onChangeStartDateTime = (date) => {
    setStartDateTime(date);

    setFilterData({
      ...filterData,
      StartDateTime: date,
    });
  };

  // HANDLER: END DATE TIME CHANGE
  const onChangeEndDateTime = (date) => {
    if (date) {
      if (date > selectedStartDateTime) {
        setEndDateTime(date);

        var endDateTimeEndOfDay = new Date(date);
        endDateTimeEndOfDay.setHours(endDateTimeEndOfDay.getHours() + 23);
        endDateTimeEndOfDay.setMinutes(endDateTimeEndOfDay.getMinutes() + 59);
        endDateTimeEndOfDay.setSeconds(endDateTimeEndOfDay.getSeconds() + 59);
        endDateTimeEndOfDay.setMilliseconds(endDateTimeEndOfDay.getMilliseconds() + 59);
        setFilterData({
          ...filterData,
          EndDateTime: endDateTimeEndOfDay,
        });
      }
    } else {
      setEndDateTime(null);

      setFilterData({
        ...filterData,
        EndDateTime: null,
      });
    }
  };

  // HANDLER: PRODUCT CHANGE
  const onChangeProductId = (productIdDropbox) => {
    setProductId(productIdDropbox);
    setSelectedPage(1);

    setFilterData({
      ...filterData,
      PageNumber: 1,
      ProductId: productIdDropbox.value,
    });
  };

  // HANDLER: END DATE TIME CHANGE
  const onChangeOverallTestStatus = (option) => {
    setOverallTestStatus(option);
    setSelectedPage(1);

    setFilterData({
      ...filterData,
      PageNumber: 1,
      OverallTestStatus: option.value,
    });
  };

  // HANDLER: RELOAD
  const onClickReload = () => {
    setSelectedCheckboxes([]);
    resetTestResult();
    getTestResultsWithFilter(filterData);
    refreshSelectedPageRange();
  };

  // HANDLER: CREATE
  const onClickCreate = () => {
    // Reset the testResult if it exists
    resetTestResult();
    navigate('/testResultForm');
  };

  // HANDLER: UPDATE
  const onClickUpdate = (id) => {
    // Set the selected testResult
    const selectedTestResult = testResults.find((testResult) => testResult.id === Number(id));
    if (selectedTestResult) {
      selectTestResult(selectedTestResult);
    }

    // Navigate to the testResultForm page
    navigate(`/testResultForm`);
  };

  // HANDLER: DELETE
  const onClickDelete = () => {
    // Delete each selected testResult by its id
    selectedCheckboxes.forEach((id) => {
      deleteTestResult(id);
    });

    // Clear the list of selected checkboxes
    setSelectedCheckboxes([]);

    // Fetch the updated list of products
    getTestResultsWithFilter(filterData);
    refreshSelectedPageRange();
  };

  // HANDLER: FILTER COLUMN
  const onClickColumn = (column) => {
    setSelectedColumn(column);

    let columnOrder = selectedColumnOrder;
    if (columnOrder === 'ASC') {
      columnOrder = 'DESC';
    } else {
      columnOrder = 'ASC';
    }

    setSelectedColumnOrder(columnOrder);

    setFilterData({
      ...filterData,
      SelectedColumn: column,
      SelectedColumnOrder: columnOrder,
    });
  };

  // HANDLER: NEXT PAGE
  const onClickNextPage = () => {
    let page = selectedPage;
    if (count && page < Math.floor(count / filterData.Limit) + 1) {
      page += 1;
      setSelectedPage(page);

      setFilterData({
        ...filterData,
        PageNumber: page,
      });
    }
  };

  // HANDLER: PREVIOUS PAGE
  const onClickPreviousPage = () => {
    let page = selectedPage;
    if (page > 1) {
      page -= 1;
      setSelectedPage(page);
    }

    setFilterData({
      ...filterData,
      PageNumber: page,
    });
  };

  // FUNCTION: GET SELECTED PAGE RANGE
  function refreshSelectedPageRange() {
    const from = (selectedPage - 1) * filterData.Limit;
    let to = (selectedPage - 1) * filterData.Limit + filterData.Limit;
    const of = count;

    if (to > count) {
      to = count;
    }

    const range = `${from} - ${to} ... ${of}`;

    setSelectedPageRange(range);

    if (count && selectedPage < Math.floor(count / filterData.Limit) + 1) {
      setNxtButtonEnabled(1);
    } else {
      setNxtButtonEnabled(0);
    }

    if (selectedPage > 1) {
      setPrvButtonEnabled(1);
    } else {
      setPrvButtonEnabled(0);
    }
  }

  // FUNCTION: GET PRODUCTS FOR DROPDOWN
  function getDropboxProducts(newProducts) {
    const newProductIdDropbox = [];

    newProductIdDropbox.push({ value: 'All', label: 'All Products' });

    newProducts.forEach((product) => {
      newProductIdDropbox.push({ value: product.name, label: product.name });
    });

    setProductIdDropbox(newProductIdDropbox);
  }

  // RENDER
  return (
    <section>
      {loading ? <Spinner /> : null}
      <section className='container'>
        <div className='container-header'>
          <div className='container-title'>
            <i className='fas fa-check-double fa-xl text-primary' />
            <h1 className='text-primary'>Test Results</h1>
          </div>
          <div className='container-action'>
            {/*--- PRODUCT ID --->*/}
            <Select
              className='dropbox'
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={selectedProductId}
              onClick={getDropboxProducts}
              onChange={onChangeProductId}
              options={productDropbox}
            />

            {/*--- SELECT OVERALL TEST STATUS --->*/}
            <Select
              className='dropbox'
              menuPortalTarget={document.body}
              value={selectedOverallTestStatus}
              onChange={onChangeOverallTestStatus}
              options={overallTestStatusDropbox}
            />

            {/*--- CREATE (ADMIN) --->
            <button
              className={
                selectedCheckboxes.length < 1
                  ? 'btn btn-primary'
                  : 'btn btn-primary btn-disabled disabled'
              }
              onClick={() => onClickCreate()}
            >
              <i className='fas fa-plus ' />
            </button>
            */}
            {/*--- EDIT (ADMIN) --->
            <button
              className={
                selectedCheckboxes.length === 1
                  ? 'btn btn-primary'
                  : 'btn btn-primary btn-disabled disabled'
              }
              onClick={() => onClickUpdate(selectedCheckboxes)}
            >
              <i className='fas fa-pencil ' />
            </button>
            */}
            {/*--- DELETE (ADMIN) --->
            <button
              className={
                selectedCheckboxes.length > 0
                  ? 'btn btn-danger'
                  : 'btn btn-danger btn-disabled disabled'
              }
              onClick={() => onClickDelete()}
            >
              <i className='fas fa-trash' />
            </button>
            */}
            {/*--- RELOAD --->*/}
            <button className={'btn btn-primary'} onClick={() => onClickReload()}>
              <i className='fas fa-rotate-right ' />
            </button>
          </div>
        </div>

        <div className='container-header container-header-light'>
          <div className='container-title'>
            {/*--- SEARCH --->*/}
            <table className='form'>
              <tbody>
                <tr>
                  <td>
                    <i className='fas fa-search fa-xl text-primary' />
                  </td>
                  <td>
                    <input
                      type='text'
                      placeholder='Serial Number'
                      value={serialNumber}
                      onChange={(event) => onChangeSerialNumber(event)}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholderText='Start Date Time'
                      selected={selectedStartDateTime}
                      onChange={onChangeStartDateTime}
                    />
                  </td>
                  <td>
                    <DatePicker
                      placeholderText='End Date Time'
                      selected={selectedEndDateTime}
                      onChange={onChangeEndDateTime}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className='container-action'>
            {/*--- PREVIOUS PAGE --->*/}
            <button
              className={
                prvButtonEnabled
                  ? 'btn btn-rangeUpDown btn-primary'
                  : 'btn btn-rangeUpDown btn-primary btn-disabled disabled'
              }
              onClick={() => onClickPreviousPage()}
            >
              <i className='fas fa-less-than ' />
            </button>{' '}
            <h1 className='text-primary medium'>{selectedPageRange}</h1>
            {/*--- NEXT PAGE --->*/}
            <button
              className={
                nxtButtonEnabled
                  ? 'btn btn-rangeUpDown btn-primary'
                  : 'btn btn-rangeUpDown btn-primary btn-disabled disabled'
              }
              onClick={() => onClickNextPage()}
            >
              <i className='fas fa-greater-than' />
            </button>
          </div>
        </div>

        <section className='container-body'>
          <div className='table'>
            <table className='table'>
              <thead>
                <tr>
                  <th>&#x2713;</th>
                  <th>
                    Start Time
                    <button
                      className={
                        selectedColumn === 'StartTime'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('StartTime')}
                    >
                      <i
                        className={
                          selectedColumn === 'StartTime' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Serial Number
                    <button
                      className={
                        selectedColumn === 'SerialNumber'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('SerialNumber')}
                    >
                      <i
                        className={
                          selectedColumn === 'SerialNumber' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Product Id
                    <button
                      className={
                        selectedColumn === 'ProductId'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('ProductId')}
                    >
                      <i
                        className={
                          selectedColumn === 'ProductId' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Firmware
                    <button
                      className={
                        selectedColumn === 'Firmware'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('Firmware')}
                    >
                      <i
                        className={
                          selectedColumn === 'Firmware' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Application
                    <button
                      className={
                        selectedColumn === 'Application'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('Application')}
                    >
                      <i
                        className={
                          selectedColumn === 'Application' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Overall Test Status
                    <button
                      className={
                        selectedColumn === 'OverallTestStatus'
                          ? 'btn btn-transparent btn-filterUpDown'
                          : 'btn btn-transparent btn-off btn-filterUpDown'
                      }
                      onClick={() => onClickColumn('OverallTestStatus')}
                    >
                      <i
                        className={
                          selectedColumn === 'OverallTestStatus' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                  <th>
                    Total Elapsed Time
                    <button
                      className={
                        selectedColumn === 'TotalElapsedTime'
                          ? 'btn btn-transparent btn-rangeUpDown'
                          : 'btn btn-transparent btn-off btn-rangeUpDown'
                      }
                      onClick={() => onClickColumn('TotalElapsedTime')}
                    >
                      <i
                        className={
                          selectedColumn === 'TotalElapsedTime' && selectedColumnOrder === 'ASC'
                            ? 'fas fa-xl fa-caret-up'
                            : 'fas fa-xl fa-caret-down'
                        }
                      />
                    </button>
                  </th>
                </tr>
              </thead>

              <tbody>
                {testResults.map((testResult) => (
                  <tr key={testResult.id}>
                    <td>
                      {/*--- TEST RESULT SELECTED (ADMIN) --->
                      <input
                        type='checkbox'
                        id={testResult.id}
                        onChange={(e) => handleCheckboxChange(e, testResult.id)}
                      />
                      */}
                    </td>
                    <td>{oleAutomationDateToLocal(testResult.StartTime)}</td>
                    <td>
                      <a onClick={(e) => onClickSerialNumberLink(e, testResult.id)} href='#!'>
                        {' '}
                        {testResult.SerialNumber}{' '}
                      </a>
                    </td>
                    <td>{testResult.ProductId}</td>
                    <td>{testResult.Firmware}</td>
                    <td>{testResult.Application}</td>
                    <td>{testResult.OverallTestStatus}</td>
                    <td>{formatTime(testResult.TotalElapsedTime)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </section>
    </section>
  );
};

TestResults.propTypes = {
  getProducts: PropTypes.func.isRequired,
  getTestResultsWithFilter: PropTypes.func.isRequired,
  deleteTestResult: PropTypes.func.isRequired,
  resetTestResult: PropTypes.func.isRequired,
  selectTestResult: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  testResult: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
  loading: state.testResult.loading,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  getProducts,
  getTestResultsWithFilter,
  deleteTestResult,
  resetTestResult,
  selectTestResult,
  loadPageName,
  loadPageIcon,
})(TestResults);
