import api from '../utils/api';
import { setAlert } from './alert';
import formatAlert from '../utils/alert';
import {
  PRODUCT_CREATE,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_READ_ALL,
  PRODUCT_SELECT,
  PRODUCT_RESET,
  PRODUCT_RESET_ALL,
  PRODUCT_LOADING,
  PRODUCT_ERROR,
} from './types';

/*************************************************************************************
 * GET PRODUCTS
 *
 * Retrieves all products from the server and dispatches an action.
 *
 *************************************************************************************/
export const getProducts = () => async (dispatch) => {
  console.log('ACTION: PRODUCT: getProducts: IN --->');

  try {
    // Get all products
    dispatch({ type: PRODUCT_LOADING });
    const res = await api.get(process.env.REACT_APP_API_URL + '/product');

    // Success
    dispatch({
      type: PRODUCT_READ_ALL,
      payload: res.data,
    });

    console.log('ACTION: PRODUCT: getProducts: OUT <---');
    return res.data; // Return all products
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: getProducts: ERROR`);
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: PRODUCT: getProducts: OUT <---');
  return null;
};

/*************************************************************************************
 * CREATE PRODUCT
 *
 * Creates a new product using the provided form data.
 *
 *************************************************************************************/
export const createProduct = (formData) => async (dispatch) => {
  console.log('ACTION: PRODUCT: createProduct: IN --->');

  try {
    // Create product
    dispatch({ type: PRODUCT_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/product', formData);

    // Success
    dispatch({
      type: PRODUCT_CREATE,
      payload: res.data,
    });

    console.log('ACTION: PRODUCT: createProduct: OUT <---');
    return res.data; // Return the created product
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: createProduct: ERROR`);
    dispatch({ type: PRODUCT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: PRODUCT: createProduct: OUT <---');
  return null;
};

/*************************************************************************************
 * UPDATE PRODUCT
 *
 * Updates a product using the provided ID and form data.
 *
 *************************************************************************************/
export const updateProduct = (id, formData) => async (dispatch) => {
  console.log('ACTION: PRODUCT: updateProduct: IN --->');

  try {
    // Update product
    dispatch({ type: PRODUCT_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + `/product/${id}`, formData);

    // Success
    dispatch({
      type: PRODUCT_UPDATE,
      payload: res.data,
    });

    console.log('ACTION: PRODUCT: updateProduct: OUT <---');
    return res.data; // Return the updated product
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: updateProduct: ERROR`);
    dispatch({ type: PRODUCT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: PRODUCT: updateProduct: OUT <---');
  return null;
};

/*************************************************************************************
 * DELETE PRODUCT
 *
 * Deletes a product with the specified ID.
 *
 *************************************************************************************/
export const deleteProduct = (id) => async (dispatch) => {
  console.log('ACTION: PRODUCT: deleteProduct: IN --->');

  try {
    // Delete product
    dispatch({ type: PRODUCT_LOADING });
    await api.delete(process.env.REACT_APP_API_URL + `/product/${id}`);

    // Success
    dispatch({
      type: PRODUCT_DELETE,
      payload: id,
    });
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: deleteProduct: ERROR`);
    dispatch({ type: PRODUCT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: PRODUCT: deleteProduct: OUT <---');
};

/*************************************************************************************
 * SELECT PRODUCT
 *
 * Selects a product and dispatches an action to update the state.
 *
 *************************************************************************************/
export const selectProduct = (product) => async (dispatch) => {
  console.log('ACTION: PRODUCT: selectProduct: IN --->');

  dispatch({
    type: PRODUCT_SELECT,
    payload: product,
  });

  console.log('ACTION: PRODUCT: selectProduct: OUT <---');
};

/*************************************************************************************
 * RESET PRODUCT
 *
 * Resets the product and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetProduct = () => async (dispatch) => {
  console.log('ACTION: PRODUCT: resetProduct: IN --->');

  dispatch({
    type: PRODUCT_RESET,
    payload: null,
  });

  console.log('ACTION: PRODUCT: resetProduct: OUT <---');
};

/*************************************************************************************
 * RESET ALL PRODUCT
 *
 * Resets all products and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetAllProduct = () => async (dispatch) => {
  console.log('ACTION: PRODUCT: resetAllProduct: IN --->');

  dispatch({
    type: PRODUCT_RESET_ALL,
    payload: null,
  });

  console.log('ACTION: PRODUCT: resetAllProduct: OUT <---');
};
