/*************************************************************************************
 * FORMAT ALERT
 *
 *
 *************************************************************************************/
function formatAlert(err) {
  let customError = '';
  let httpError = '';
  let alert = '';

  if (!err) {
    return 'Undefined error';
  }

  if (err.isAxiosError) {
    if (err.response.statusText === '') {
      httpError = err.response.status + ' - HTTP Error';
    } else {
      httpError = err.response.status + ' - ' + err.response.statusText;
    }
  }

  if (!err.response && err.message) {
    customError = 'The service is unavailable: (' + err.message + ')';
  } else if (!err.response) {
    customError = 'Undefined error';
  } else if (err.response.data && err.response.data.msg) {
    customError = err.response.data.msg;
  } else if (err.response.data && err.response.data.errors) {
    customError = err.response.data.errors[0].msg;
  } else if (
    (err.response.data && err.response.data.title) ||
    (err.response.data && err.response.data.detail)
  ) {
    let title = '';
    let detail = '';

    if (err.response.data.title) {
      title = err.response.data.title;
    }

    if (err.response.data.detail) {
      detail = ': ' + err.response.data.detail;
    }

    customError = title + detail;
  }

  // Custom error only
  if (customError && customError !== '' && (!httpError || httpError === '')) {
    alert = customError;
  }
  // Http error only
  else if ((!customError || customError === '') && httpError && httpError !== '') {
    alert = httpError;
  }
  // Custom error and http error
  else if (customError && customError !== '' && httpError && httpError !== '') {
    alert = '(' + httpError + ') : ' + customError;
  } else {
    alert = 'Undefined error';
  }

  return alert;
}

export default formatAlert;
