import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createTestResult, updateTestResult } from '../../actions/testResult';
import { useNavigate } from 'react-router-dom';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * Renders a form for creating or updating a testResult.
 *
 *************************************************************************************/
const TestResultForm = ({
  createTestResult,
  updateTestResult,
  pageName,
  loadPageName,
  loadPageIcon,
  testResult: { testResult, loading },
}) => {
  // INITIALIZATIONS AND STATES
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    SerialNumber: testResult ? testResult.SerialNumber : '',
    ProductId: testResult ? testResult.ProductId : '',
    Firmware: testResult ? testResult.Firmware : '',
    Application: testResult ? testResult.Application : '',
    OverallTestStatus: testResult ? testResult.OverallTestStatus : '',
    StartTime: testResult ? testResult.StartTime : '',
    TotalElapsedTime: testResult ? testResult.TotalElapsedTime : '',
  });

  const {
    SerialNumber,
    ProductId,
    Firmware,
    Application,
    OverallTestStatus,
    StartTime,
    TotalElapsedTime,
  } = formData;

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Test Results > Test Result') {
      loadPageName('Test Results > Test Result');
      loadPageIcon('fa-check-double');
    }
  }, [testResult, formData]);

  // HANDLER: FIELD CHANGE
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // HANDLER: CLICK SAVE
  const onClickSave = (e) => {
    e.preventDefault();

    // Create or update the testResult
    if (!testResult) {
      createTestResult(formData).then((data) => {
        if (data) {
          navigate('/testResults');
        }
      });
    } else {
      updateTestResult(testResult.id, formData).then((data) => {
        if (data) {
          navigate('/testResults');
        }
      });
    }
  };

  // HANDLER: CLICK BACK
  const onClickBack = () => {
    navigate('/testResults');
  };

  // RENDER
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <section className='container'>
            {/*--- HEADER --->*/}
            <div className='container-header'>
              {/*--- TITLE ---*/}
              <div className='container-title'>
                <i className='fas fa-check-double fa-xl text-primary' />
                <h1 className='text-primary'>Test Result</h1>
              </div>
              {/*--- ACTION --->*/}
              <div className='container-action'>
                {/*--- BACK --->*/}
                <button className={'btn btn-primary'} onClick={() => onClickBack()}>
                  <i className='fas fa-arrow-left' />
                </button>
              </div>
            </div>

            {/*--- BODY --->*/}
            <section className='container-body'>
              <form className='form' onSubmit={onClickSave}>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='SerialNumber'
                    name='SerialNumber'
                    value={SerialNumber}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='ProductId'
                    name='ProductId'
                    value={ProductId}
                    onChange={(e) => onChange(e)}
                  />
                </div>

                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Firmware'
                    name='Firmware'
                    value={Firmware}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Application'
                    name='Application'
                    value={Application}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='OverallTestStatus'
                    name='OverallTestStatus'
                    value={OverallTestStatus}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='StartTime'
                    name='StartTime'
                    value={StartTime}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='TotalElapsedTime'
                    name='TotalElapsedTime'
                    value={TotalElapsedTime}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <input type='submit' className='btn btn-primary' value='Save' />
              </form>
            </section>
          </section>
        </Fragment>
      )}
    </section>
  );
};

TestResultForm.propTypes = {
  createTestResult: PropTypes.func.isRequired,
  updateTestResult: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  testResult: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
  loading: state.testResult.loading,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  createTestResult,
  updateTestResult,
  loadPageName,
  loadPageIcon,
})(TestResultForm);
