import api from '../utils/api';
import formatAlert from '../utils/alert';
import { setAlert } from './alert';
import {
  AUTH_REGISTER_OK,
  AUTH_REGISTER_ERROR,
  AUTH_LOAD_USER_OK,
  AUTH_LOAD_USER_ERROR,
  AUTH_LOADING,
  AUTH_LOGIN_OK,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
} from './types';

/*************************************************************************************
 * LOAD USER
 *
 * Load authenticated user data and dispatch it to the Redux store
 *
 *************************************************************************************/
export const loadUser = () => async (dispatch) => {
  console.log('ACTION: AUTH: loadUser (' + process.env.REACT_APP_API_URL + '): IN --->');

  try {
    // Load user
    dispatch({ type: AUTH_LOADING });
    const res = await api.get(process.env.REACT_APP_API_URL + '/auth');

    // Server unreachable
    if (!res || !res.data || !res.data.name || !res.data.name === '') {
      const err = new Error('Server Unreachable');
      throw err;
    } else {
      // Success
      dispatch({ type: AUTH_LOAD_USER_OK, payload: res.data });
      console.log('ACTION: AUTH: loadUser: ', res.data.name);
    }
  } catch (err) {
    // Error
    dispatch({ type: AUTH_LOAD_USER_ERROR });
    console.log(`ACTION: AUTH: loadUser: ERROR (No User Logged In)`);
  }

  console.log('ACTION: AUTH: loadUser: OUT <---');
};

/*************************************************************************************
 * REGISTER
 *
 * Registers a new user.
 *
 *************************************************************************************/
export const register = (formData) => async (dispatch) => {
  console.log('REGISTER: IN --->');

  try {
    // Register user
    dispatch({ type: AUTH_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/user', formData);

    // Server unreachable
    if (!res || !res.data || !res.data.name) {
      const err = new Error('Server Unreachable');
      throw err;
    } else {
      dispatch({ type: AUTH_REGISTER_OK, payload: res.data });

      // Load user registered
      dispatch(loadUser());
    }
  } catch (err) {
    // Error
    dispatch({ type: AUTH_REGISTER_ERROR });
    const errMsg = formatAlert(err);
    dispatch(setAlert(errMsg, 'danger'));
    console.log(`REGISTER: ERROR (${errMsg})`);
  }

  console.log('REGISTER: OUT <---');
};

/*************************************************************************************
 * LOGIN
 *
 * User login.
 *
 *************************************************************************************/
export const login = (formData) => async (dispatch) => {
  console.log('ACTION: AUTH: login: IN --->');

  try {
    // Login user
    dispatch({ type: AUTH_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/auth', formData);
    dispatch({ type: AUTH_LOGIN_OK, payload: res.data });

    // Server unreachable
    if (!res || !res.data || !res.data.token || res.data.token === '') {
      const err = new Error('Server Unreachable');
      throw err;
    } else {
      // Success
      dispatch({ type: AUTH_LOAD_USER_OK, payload: res.data });

      // Load user received
      dispatch(loadUser());
    }
  } catch (err) {
    // Error
    dispatch({ type: AUTH_LOGIN_ERROR });
    const errMsg = formatAlert(err);
    dispatch(setAlert(errMsg, 'danger'));
    console.log(`ACTION: AUTH: login: ERROR (${errMsg})`);
  }

  console.log('ACTION: AUTH: login: OUT <---');
};

/*************************************************************************************
 * LOGOUT
 *
 * User logout.
 *
 *************************************************************************************/
export const logout = () => async (dispatch) => {
  console.log('AUTH_LOGOUT: IN --->');

  dispatch({ type: AUTH_LOGOUT });

  console.log('AUTH_LOGOUT: OUT <---');
};
