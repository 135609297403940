import api from './api';

/*************************************************************************************
 * SET AUTH TOKEN
 *
 * Store our JWT in LS and set axios headers if we do have a token
 *
 *************************************************************************************/
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['x-auth-token'] = token;
    localStorage.setItem('token', token);
  } else {
    console.log('     setAuthToken: NONE');
    delete api.defaults.headers.common['x-auth-token'];
    localStorage.removeItem('token');
  }
};

export default setAuthToken;
