import {
  AUTH_REGISTER_OK,
  AUTH_LOAD_USER_OK,
  AUTH_LOAD_USER_ERROR,
  AUTH_LOADING,
  AUTH_LOGIN_OK,
  AUTH_LOGIN_ERROR,
  AUTH_LOGOUT,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: false,
  loading: false,
  user: null,
};

/*************************************************************************************
 * Reducer function for handling authentication related actions.
 *
 *************************************************************************************/
function authReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };

    case AUTH_LOAD_USER_OK:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };

    case AUTH_REGISTER_OK:
    case AUTH_LOGIN_OK:
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      };

    case AUTH_LOGIN_ERROR:
    case AUTH_LOAD_USER_ERROR:
    case AUTH_LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };

    default:
      return state;
  }
}

export default authReducer;
