import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/*************************************************************************************
 * Component that displays a list of alerts.
 *
 *************************************************************************************/
const Alert = ({ alerts }) => {
  const firstAlert = alerts.find((alert) => alert.alertType === 'danger');
  return (
    <div className='alert-wrapper'>
      {firstAlert && (
        <div key={firstAlert.id} className={`alert alert-${firstAlert.alertType}`}>
          {firstAlert.msg}
        </div>
      )}
    </div>
  );
};

Alert.propTypes = {
  alerts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
