import { v4 as uuidv4 } from 'uuid';
import { ALERT_SET, ALERT_RESET } from './types';

/*************************************************************************************
 * SET ALERT
 *
 * Sets an alert with the given message, alert type, and optional timeout.
 *
 *************************************************************************************/
export const setAlert =
  (msg, alertType, timeout = 3000) =>
  (dispatch) => {
    console.log('ALERT: SET ALERT: IN --->');

    // Generate a unique identifier for the alert
    const id = uuidv4();

    // Dispatch the alert
    console.log('ALERT: SET ALERT: ', { msg });
    dispatch({
      type: ALERT_SET,
      payload: { msg, alertType, id },
    });

    // Remove the alert after the timeout
    setTimeout(() => {
      dispatch({ type: ALERT_RESET, payload: id });
    }, timeout);

    console.log('ALERT: SET ALERT: OUT <---');
  };
