import api from '../utils/api';
import { setAlert } from './alert';
import formatAlert from '../utils/alert';
import {
  TEST_RESULT_DETAIL_CREATE,
  TEST_RESULT_DETAIL_UPDATE,
  TEST_RESULT_DETAIL_DELETE,
  TEST_RESULT_DETAIL_READ_ALL,
  TEST_RESULT_DETAIL_SELECT,
  TEST_RESULT_DETAIL_RESET,
  TEST_RESULT_DETAIL_RESET_ALL,
  TEST_RESULT_DETAIL_LOADING,
  TEST_RESULT_DETAIL_ERROR,
} from './types';

/*************************************************************************************
 * GET TEST RESULT DETAILS
 *
 * Retrieves all testResultDetails for a specific testResult from the API and dispatches an action.
 *
 *************************************************************************************/
export const getTestResultDetails = (id) => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: getTestResultDetails: IN --->');

  try {
    // Get all testResultDetails
    dispatch({ type: TEST_RESULT_DETAIL_LOADING });
    const res = await api.get(
      process.env.REACT_APP_API_URL + `/testResultDetail/forTestResult/${id}`
    );

    // Success
    dispatch({
      type: TEST_RESULT_DETAIL_READ_ALL,
      payload: res.data,
    });
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT DETAIL: getTestResultDetails: ERROR`);
    dispatch({ type: TEST_RESULT_DETAIL_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT DETAIL: getTestResultDetails: OUT <---');
};

/*************************************************************************************
 * CREATE TEST RESULT DETAIL
 *
 * Creates a new testResultDetail using the provided form data.
 *
 *************************************************************************************/
export const createTestResultDetail = (formData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: createTestResultDetail: IN --->');

  try {
    // Create testResultDetail
    dispatch({ type: TEST_RESULT_DETAIL_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/testResultDetail', formData);

    // Success
    dispatch({
      type: TEST_RESULT_DETAIL_CREATE,
      payload: res.data,
    });

    console.log('ACTION: TEST RESULT DETAIL: createTestResultDetail: OUT <---');
    return res.data; // Return the created testResultDetail
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT DETAIL: createTestResultDetail: ERROR`);
    dispatch({ type: TEST_RESULT_DETAIL_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT DETAIL: createTestResultDetail: OUT <---');
  return null;
};

/*************************************************************************************
 * UPDATE TEST RESULT DETAIL
 *
 * Updates a testResultDetail using the provided ID and form data.
 *
 *************************************************************************************/
export const updateTestResultDetail = (id, formData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: updateTestResultDetail: IN --->');

  try {
    // Update testResultDetail
    dispatch({ type: TEST_RESULT_DETAIL_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + `/testResultDetail/${id}`, formData);

    // Success
    dispatch({
      type: TEST_RESULT_DETAIL_UPDATE,
      payload: res.data,
    });

    console.log('ACTION: TEST RESULT DETAIL: updateTestResultDetail: OUT <---');
    return res.data; // Return the updated testResultDetail
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: createProduct: ERROR`);
    dispatch({ type: TEST_RESULT_DETAIL_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT DETAIL: updateTestResultDetail: OUT <---');
  return null;
};

/*************************************************************************************
 * DELETE TEST RESULT DETAIL
 *
 * Deletes a testResultDetail with the specified ID.
 *
 *************************************************************************************/
export const deleteTestResultDetail = (id) => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: deleteTestResultDetail: IN --->');

  try {
    // Delete testResultDetail
    dispatch({ type: TEST_RESULT_DETAIL_LOADING });
    await api.delete(process.env.REACT_APP_API_URL + `/testResultDetail/${id}`);

    // Success
    dispatch({
      type: TEST_RESULT_DETAIL_DELETE,
      payload: id,
    });
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT DETAIL: deleteTestResultDetail: ERROR`);
    dispatch({ type: TEST_RESULT_DETAIL_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT DETAIL: deleteTestResultDetail: OUT <---');
};

/*************************************************************************************
 * SELECT TEST RESULT DETAIL
 *
 * Selects a testResultDetail and dispatches an action to update the state.
 *
 *************************************************************************************/
export const selectTestResultDetail = (testResultDetail) => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: selectTestResultDetail: IN --->');

  dispatch({
    type: TEST_RESULT_DETAIL_SELECT,
    payload: testResultDetail,
  });

  console.log('ACTION: TEST RESULT DETAIL: selectTestResultDetail: OUT <---');
};

/*************************************************************************************
 * Resets all testResultDetails and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetAllTestResultDetail = () => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: resetAllTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_DETAIL_RESET_ALL,
    payload: null,
  });

  console.log('ACTION: TEST RESULT DETAIL: resetAllTestResult: OUT <---');
};

/*************************************************************************************
 * RESET TEST RESULT DETAIL
 *
 * Resets the testResultDetail and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetTestResultDetail = () => async (dispatch) => {
  console.log('ACTION: TEST RESULT DETAIL: resetTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_DETAIL_RESET,
    payload: null,
  });

  console.log('ACTION: TEST RESULT DETAIL: resetTestResult: OUT <---');
};
