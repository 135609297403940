import { Bar } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import { getTestResultsWithFilter, resetTestResult } from '../../actions/testResult';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
const moment = require('moment');

const ChartWeek = ({
  getTestResultsWithFilter,
  testResult: { testResults, loading, count },
  weekNumber,
  weekYear,
  productId,
}) => {
  // INITIALIZATIONS AND STATES

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  const [weekLabels, setweekLabels] = useState(['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);

  const [weekData, setWeekData] = useState({
    labels: weekLabels,
    datasets: [
      {
        label: 'Fail',
        data: [0, 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Pass',
        data: [0, 0, 0, 0, 0, 0, 0],
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  });

  const [weekOptions, setweekOptions] = useState({
    maintainAspectRatio: false, // Set to false to allow the chart to be resized
    responsive: true, // Set to true to enable responsiveness
    plugins: {
      legend: {
        position: 'right',
      },
      title: {
        display: true,
        text: 'Test Results',
        padding: 20,
        font: {
          size: 16,
        },
      },
      datalabels: {
        display: true,
        color: 'black',
        formatter: Math.round,
        anchor: 'end',
        offset: 0,
        align: 'end',
      },
    },
  });

  const [curStartDateTime, setCurStartDateTime] = useState(
    getCurStartDateTime(weekNumber, weekYear)
  );
  const [curEndDateTime, setCurEndDateTime] = useState(getCurEndDateTime(weekNumber, weekYear));
  const [curWeekNumber, setCurWeekNumber] = useState(weekNumber);
  const [curWeekYear, setCurWeekYear] = useState(weekYear);
  const [prvPageWeekNumber, setPrvPageWeekNumber] = useState(0);
  const [prvPageProductId, setPrvPageProductId] = useState(0);

  const [filterData, setFilterData] = useState({
    SerialNumber: '',
    StartDateTime: curStartDateTime,
    EndDateTime: curEndDateTime,
    OverallTestStatus: 'All',
    ProductId: productId,
    Limit: 0,
    SelectedColumn: 'StartTime',
    PageNumber: 0,
  });

  // USE EFFECT
  useEffect(() => {
    if (prvPageWeekNumber !== weekNumber || prvPageProductId !== productId) {
      filterData.ProductId = productId;
      filterData.StartDateTime = getCurStartDateTime(weekNumber, weekYear);
      filterData.EndDateTime = getCurEndDateTime(weekNumber, weekYear);
      getTestResultsWithFilter(filterData).then((testResults) => {
        if (testResults) {
          refreshChart(testResults);
        }
      });
    }
  }, [weekNumber, productId]);

  function getCurStartDateTime(weekNumber, weekYear) {
    // This week monday
    const mondayDate = moment().day('Monday').year(weekYear).week(weekNumber).toDate();
    mondayDate.setHours(0, 0, 0, 0);

    return mondayDate;
  }

  function getCurEndDateTime(weekNumber, weekYear) {
    // This week sunday
    const mondayDate = moment().day('Monday').year(weekYear).week(weekNumber).toDate();
    let sundayDate = moment(mondayDate).add(6, 'days').toDate();
    sundayDate.setHours(23, 59, 59, 999);

    return sundayDate;
  }

  function refreshChart(testResultsChart) {
    console.log('>>>> refreshChart');

    const newWeekLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const newWeekData = {
      labels: newWeekLabels,
      datasets: [
        {
          label: 'Fail',
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Pass',
          data: [0, 0, 0, 0, 0, 0, 0],
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
      ],
    };

    let totalFail = 0;
    let totalPass = 0;
    let totalTest = 0;

    if (testResultsChart && testResultsChart.length > 0) {
      testResultsChart.forEach((element) => {
        for (let i = 0; i <= 7; i++) {
          const utcDate = new Date((element.StartTime - 25569) * 86400 * 1000);
          if (utcDate.getDay() === i + 1) {
            // 1 = Monday, 7 = Sunday
            if (element.OverallTestStatus === 'Passed') {
              newWeekData.datasets[1].data[i] += 1;
              totalPass += 1;
              totalTest += 1;
            } else {
              newWeekData.datasets[0].data[i] += 1;
              totalFail += 1;
              totalTest += 1;
            }
          }
        }
      });
    }

    const today = new Date();
    const startDateTime = getCurStartDateTime(weekNumber, weekYear);
    for (let i = 0; i < 7; i++) {
      if (
        startDateTime.getDate() === today.getDate() &&
        startDateTime.getMonth() === today.getMonth() &&
        startDateTime.getFullYear() === today.getFullYear()
      ) {
        newWeekLabels[i] = startDateTime.toLocaleString('default', { day: 'numeric' }) + ' - TODAY';
      } else {
        newWeekLabels[i] =
          startDateTime.toLocaleString('default', { day: 'numeric' }) + ' - ' + newWeekLabels[i];
      }

      startDateTime.setDate(startDateTime.getDate() + 1);
    }

    let fail = 0;
    let pass = 0;
    if (totalTest !== 0) {
      fail = Math.round(((totalFail / totalTest) * 100 * 100) / 100);
      pass = Math.round(((totalPass / totalTest) * 100 * 100) / 100);
    }
    const failLegend =
      '(' +
      totalFail +
      ' / ' +
      totalTest +
      ') ' +
      newWeekData.datasets[0].label +
      ' - ' +
      fail +
      '%';
    const passLegend =
      '(' +
      totalPass +
      ' / ' +
      totalTest +
      ') ' +
      newWeekData.datasets[1].label +
      ' - ' +
      pass +
      '%';
    newWeekData.datasets[0].label = failLegend;
    newWeekData.datasets[1].label = passLegend;
    if (productId === 'All') {
      weekOptions.plugins.title.text = '(' + totalTest + ') Test Results';
    } else {
      weekOptions.plugins.title.text = '(' + totalTest + ') - ' + productId + ' Test Results';
    }

    setCurWeekYear(weekYear);
    setCurWeekNumber(weekNumber);
    setWeekData(newWeekData);
    setweekLabels(newWeekLabels);
    setweekOptions(weekOptions);
    setCurStartDateTime(getCurStartDateTime(weekNumber, weekYear));
    setCurEndDateTime(getCurEndDateTime(weekNumber, weekYear));
    setPrvPageWeekNumber(weekNumber);
  }

  return (
    <section>
      {loading ? <Spinner /> : null}
      <div>
        <section className='container-header'>
          <h1 className='text-primary'>
            {'Week ('}
            {curWeekNumber}
            {') - Starting '}
            {curStartDateTime.toLocaleString('default', {
              month: 'long',
              day: 'numeric',
            })}{' '}
            {curWeekYear}
          </h1>
        </section>
        <section className='container-body' style={{ width: '100%', height: '250px' }}>
          <Bar data={weekData} plugins={[ChartDataLabels]} options={weekOptions} />
        </section>
      </div>
    </section>
  );
};

ChartWeek.propTypes = {
  getTestResultsWithFilter: PropTypes.func.isRequired,
  resetTestResult: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  count: PropTypes.number,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  getTestResultsWithFilter,
  resetTestResult,
})(ChartWeek);

export function oleAutomationDateToLocal(oleAutomationDate) {}
