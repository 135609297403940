/*************************************************************************************
 * FOMAT DATE
 *
 * Example: 2024-01-23T13:04:35.000Z to 1/23/2024
 *
 *************************************************************************************/
function formatDate(date) {
  return new Intl.DateTimeFormat().format(new Date(date));
}

/*************************************************************************************
 * OLE AUTOMATION DATE TO LOCAL
 *
 * Example: 45357.40425737269 to 3/6/2024, 9:42:07 AM
 *
 *************************************************************************************/
function oleAutomationDateToLocal(oleDate) {
  const utcDate = new Date((oleDate - 25569) * 86400 * 1000);
  const localDate = utcDate.toLocaleString('en-US', { timeZone: 'UTC' });
  return localDate;
}

/*************************************************************************************
 * FORMAT TIME
 *
 * Example: 1.3 to 00:01:300
 *
 *************************************************************************************/
function formatTime(totalSeconds) {
  var minutes = Math.floor((totalSeconds % 3600) / 60);
  var seconds = Math.floor(totalSeconds % 60); // Round down to remove decimal places
  var milliseconds = Math.floor((totalSeconds * 1000) % 1000); // Calculate milliseconds
  var formattedTime =
    (minutes < 10 ? '0' : '') +
    minutes +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds +
    ':' +
    (milliseconds < 10 ? '00' : milliseconds < 100 ? '0' : '') +
    milliseconds;
  return formattedTime;
}

export { formatDate, formatTime, oleAutomationDateToLocal };
