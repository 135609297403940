import React, { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * Renders the login component.
 *
 *************************************************************************************/
const Login = ({ login, loadPageName, loadPageIcon, isAuthenticated, loading, pageName }) => {
  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Login') {
      loadPageName('Login');
      loadPageIcon('fa-user');
    }
  });

  // INITIALIZATIONS AND STATES
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;

  if (isAuthenticated) {
    return <Navigate to='/testResult/chart' />;
  }

  // HANDLER: FIELD CHANGE
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // HANDLER: SUBMIT
  const onSubmit = async (e) => {
    e.preventDefault();

    login({ email, password });
  };

  // RENDER
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <section className='container container-login'>
            <div className='container-header'>
              <div className='container-title'>
                <i className='fas fa-user fa-xl text-primary' />
                <h1 className='text-primary'>Login</h1>
              </div>
            </div>
            <section className='container-body'>
              {/*--- SUBMIT --->*/}
              <form className='form' onSubmit={onSubmit}>
                <div className='form-group'>
                  <input
                    type='email'
                    placeholder='Email'
                    name='email'
                    value={email}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='password'
                    placeholder='Password'
                    name='password'
                    value={password}
                    minLength='6'
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <input type='submit' className='btn btn-primary' value='Login' />
              </form>
            </section>
          </section>
        </Fragment>
      )}
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  loading: PropTypes.bool,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, { login, loadPageName, loadPageIcon })(Login);
