import { NAVBAR_LOADPAGENAME, NAVBAR_LOADPAGEICON } from '../actions/types';

const initialState = {
  pageName: 'Login',
  pageIcon: 'fa-user',
};

/*************************************************************************************
 * Reducer function for handling navbar related actions.
 *
 *************************************************************************************/
function navbarReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case NAVBAR_LOADPAGENAME:
      return {
        ...state,
        pageName: payload,
      };

    case NAVBAR_LOADPAGEICON:
      return {
        ...state,
        pageIcon: payload,
      };

    default:
      return state;
  }
}

export default navbarReducer;
