import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import { getProducts } from '../../actions/product';
import { getTestResultsWithFilter } from '../../actions/testResult';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import ChartWeek from './ChartWeek';
const moment = require('moment');

const TestResultsChartWeek = ({
  loadPageName,
  loadPageIcon,
  pageName,
  getProducts,
  product: { products, product },
}) => {
  console.log('TEST RESULTS CHART WEEK: PAGE: IN --->');

  // INITIALIZATIONS AND STATES
  const timeRangeDropbox = [{ value: 'Week', label: 'Week' }];

  const [productIdDropbox, setProductIdDropbox] = useState([
    {
      value: 'All',
      label: 'All Products',
    },
  ]);
  const [curWeekNumber, setCurWeekNumber] = useState(getTodayWeekNumber());
  const [curWeekYear, setCurWeekYear] = useState(getTodayWeekYear());
  const [prvWeekNumber, setPrvWeekNumber] = useState(getPrvWeekNumber(getTodayWeekNumber()));
  const [prvWeekYear, setPrvWeekYear] = useState(
    getPrvWeekYear(getTodayWeekNumber(), getTodayWeekYear())
  );
  const [timeRange, setTimeRange] = useState(timeRangeDropbox[0]);
  const [productId, setProductId] = useState(productIdDropbox[0]);
  const [nextWeekBtnEnabled, setNextWeekBtnEnabled] = useState(0);

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Dashboard') {
      loadPageName('Dashboard');
      loadPageIcon('fa-area-chart');
    }

    getProducts().then((products) => {
      if (products !== null) {
        getDropboxProducts(products);
      }
    });
  }, [getProducts, product]);

  // HANDLER: TIME RANGE CHANGE
  const onChangeTimeRange = (timeRangeDropbox) => {
    setTimeRange(timeRangeDropbox);
    setNextWeekBtnEnabled(0);

    const curWeekNumberPrevious = getTodayWeekNumber();
    setCurWeekNumber(curWeekNumberPrevious);
    const curWeekYearPrevious = getTodayWeekYear();
    setCurWeekYear(curWeekYearPrevious);

    const prvWeekNumberPevious = getPrvWeekNumber(curWeekNumberPrevious);
    setPrvWeekNumber(prvWeekNumberPevious);
    const prvWeekYearPrevious = getPrvWeekYear(curWeekNumberPrevious, curWeekYearPrevious);
    setPrvWeekYear(prvWeekYearPrevious);
  };

  // HANDLER: PRODUCT CHANGE
  const onChangeProductId = (productIdDropbox) => {
    setProductId(productIdDropbox);
  };

  // HANDLER: PREVIOUS WEEK
  const onClickPreviousPage = () => {
    const curWeekNumberPrevious = getPrvWeekNumber(curWeekNumber);
    setCurWeekNumber(curWeekNumberPrevious);
    const curWeekYearPrevious = getPrvWeekYear(curWeekNumber, curWeekYear);
    setCurWeekYear(curWeekYearPrevious);

    const prvWeekNumberPevious = getPrvWeekNumber(curWeekNumberPrevious);
    setPrvWeekNumber(prvWeekNumberPevious);
    const prvWeekYearPrevious = getPrvWeekYear(curWeekNumberPrevious, curWeekYearPrevious);
    setPrvWeekYear(prvWeekYearPrevious);

    setNextWeekBtnEnabled(1);
  };

  // HANDLER: NEXT WEEK
  const onClickNextPage = () => {
    const curWeekNumberNext = getNxtWeekNumber(curWeekNumber);
    setCurWeekNumber(curWeekNumberNext);
    const curWeekYearNext = getNxtWeekYear(curWeekNumber, curWeekYear);
    setCurWeekYear(curWeekYearNext);

    const prvWeekNumberPevious = getPrvWeekNumber(curWeekNumberNext);
    setPrvWeekNumber(prvWeekNumberPevious);
    const prvWeekYearPrevious = getPrvWeekYear(curWeekNumberNext, curWeekYearNext);
    setPrvWeekYear(prvWeekYearPrevious);

    if (curWeekNumberNext === getTodayWeekNumber()) {
      setNextWeekBtnEnabled(0);
    }
  };

  // GET CURRENT WEEK NUMBER
  function getTodayWeekNumber() {
    const todayWeekNumber = moment().isoWeek();

    return todayWeekNumber;
  }

  // GET CURRENT WEEK YEAR
  function getTodayWeekYear() {
    const todayYear = moment().year();

    return todayYear;
  }

  // GET PREVIOUS WEEK NUMBER
  function getPrvWeekNumber(week) {
    let prvWeek = week;

    if (prvWeek === 1) {
      prvWeek = 52;
    } else {
      prvWeek = week - 1;
    }

    return prvWeek;
  }

  // GET THIS WEEK YEAR
  function getPrvWeekYear(week, year) {
    let prvYear = year;

    if (week === 1) {
      prvYear = year - 1;
    }

    return prvYear;
  }

  // GET NEXT WEEK NUMBER
  function getNxtWeekNumber(week) {
    let nxtWeek = week;

    if (nxtWeek === 52) {
      nxtWeek = 1;
    } else {
      nxtWeek = week + 1;
    }

    return nxtWeek;
  }

  // GET NEXT WEEK YEAR
  function getNxtWeekYear(weak, year) {
    let nxtYear = year;

    if (weak === 52) {
      nxtYear = year + 1;
    }

    return nxtYear;
  }

  // GET PRODUCTS FOR DROPDOWN
  function getDropboxProducts(newProducts) {
    const newProductIdDropbox = [];

    newProductIdDropbox.push({ value: 'All', label: 'All Products' });

    newProducts.forEach((product) => {
      newProductIdDropbox.push({ value: product.name, label: product.name });
    });

    setProductIdDropbox(newProductIdDropbox);
  }

  console.log('TEST RESULTS CHART WEEK: PAGE: OUT --->');

  return (
    <section>
      <section className='container'>
        <div className='container-header'>
          <div className='container-title'>
            <i className='fas fa-area-chart fa-xl text-primary' />
            <h1 className='text-primary'>Dashboard</h1>
          </div>
          <div className='container-action'>
            {/*--- WEEK, MONTH, YEAR --->*/}
            <Select
              className='dropbox'
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={timeRange}
              onChange={onChangeTimeRange}
              options={timeRangeDropbox}
            />
            {/*--- PRODUCT ID --->*/}
            <Select
              className='dropbox'
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              value={productId}
              onClick={getDropboxProducts}
              onChange={onChangeProductId}
              options={productIdDropbox}
            />
          </div>
        </div>

        <div className='container-header container-header-light'>
          <div className='container-action'>
            {/*--- PREVIOUS PAGE --->*/}
            <button
              className='btn btn-rangeUpDown btn-primary'
              onClick={() => onClickPreviousPage()}
            >
              <i className='fas fa-less-than ' />
            </button>

            {/*--- NEXT PAGE --->*/}
            <button
              className={
                nextWeekBtnEnabled
                  ? 'btn btn-rangeUpDown btn-primary'
                  : 'btn btn-rangeUpDown btn-primary btn-disabled'
              }
              onClick={() => onClickNextPage()}
            >
              <i className='fas fa-greater-than ' />
            </button>
          </div>
        </div>

        <section className='container'>
          <ChartWeek
            weekNumber={curWeekNumber}
            weekYear={curWeekYear}
            productId={productId.value}
          />

          {/*--- To display also the previous week --->
          <ChartWeek
            weekNumber={prvWeekNumber}
            weekYear={prvWeekYear}
            productId={productId.value}
          />
          */}
        </section>
      </section>
    </section>
  );
};

TestResultsChartWeek.propTypes = {
  getProducts: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  product: state.product,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  getProducts,
  getTestResultsWithFilter,
  loadPageName,
  loadPageIcon,
})(TestResultsChartWeek);
