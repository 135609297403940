import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * PrivateRoute component.
 * Renders the given component only if the user is authenticated.
 * Otherwise, redirects the user to the login page.
 *
 *************************************************************************************/
const PrivateRoute = ({ component: Component, auth: { isAuthenticated, loading } }) => {
  // Render a spinner if the authentication process is still loading
  if (loading) {
    return <Spinner />;
  }

  // Render the given component if the user is authenticated
  if (isAuthenticated) {
    return <Component />;
  }

  // Redirect the user to the login page
  return <Navigate to='/login' />;
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
