import axios from 'axios';
import store from '../store';
import { AUTH_LOGOUT } from '../actions/types';

/*************************************************************************************
 * Create an instance of axios with a base url
 *
 *************************************************************************************/
const api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

/*************************************************************************************
 * - Intercept any error responses from the api and check if the token is no longer
 *   valid.
 * - If the token has expired, we will get a 401 response. The user is then no longer
 *   authenticated.
 * - Logout the user if the token has expired.
 *
 *************************************************************************************/
api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch({ type: AUTH_LOGOUT });
    }
    return Promise.reject(err);
  }
);

export default api;
