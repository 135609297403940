export const ALERT_SET = 'ALERT_SET';
export const ALERT_RESET = 'ALERT_RESET';

export const AUTH_REGISTER_OK = 'AUTH_REGISTER_OK';
export const AUTH_REGISTER_ERROR = 'AUTH_REGISTER_ERROR';
export const AUTH_LOAD_USER_OK = 'AUTH_LOAD_USER_OK';
export const AUTH_LOAD_USER_ERROR = 'AUTH_LOAD_USER_ERROR';
export const AUTH_LOGIN_OK = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT = 'AUTHLOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';

export const PRODUCT_SELECT = 'PRODUCT_SELECT';
export const PRODUCT_RESET = 'PRODUCT_RESET';
export const PRODUCT_RESET_ALL = 'PRODUCT_RESET_ALL';
export const PRODUCT_READ_ALL = 'PRODUCT_READ_ALL';
export const PRODUCT_CREATE = 'PRODUCT_CREATE';
export const PRODUCT_READ = 'PRODUCT_READ';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
export const PRODUCT_DELETE = 'PRODUCT_DELETE';
export const PRODUCT_LOADING = 'PRODUCT_LOADING';
export const PRODUCT_ERROR = 'PRODUCT_ERROR';
export const TEST_RESULT_SELECT = 'TEST_RESULT_SELECT';
export const TEST_RESULT_RESET = 'TEST_RESULT_RESET';
export const TEST_RESULT_RESET_ALL = 'TEST_RESULT_RESET_ALL';
export const TEST_RESULT_SET_FILTER = 'TEST_RESULT_SET_FILTER';
export const TEST_RESULT_READ_ALL = 'TEST_RESULT_READ_ALL';
export const TEST_RESULTS_WITH_FILTER_READ = 'TEST_RESULTS_WITH_FILTER_READ';
export const TEST_RESULT_CREATE = 'TEST_RESULT_CREATE';
export const TEST_RESULT_READ = 'TEST_RESULT_READ';
export const TEST_RESULT_UPDATE = 'TEST_RESULT_UPDATE';
export const TEST_RESULT_DELETE = 'TEST_RESULT_DELETE';
export const TEST_RESULT_LOADING = 'TEST_RESULT_LOADING';
export const TEST_RESULT_ERROR = 'TEST_RESULT_ERROR';

export const TEST_RESULT_DETAIL_SELECT = 'TEST_RESULT_DETAIL_SELECT';
export const TEST_RESULT_DETAIL_RESET = 'TEST_RESULT_DETAIL_RESET';
export const TEST_RESULT_DETAIL_RESET_ALL = 'TEST_RESULT_DETAIL_RESET_ALL';
export const TEST_RESULT_DETAIL_READ_ALL = 'TEST_RESULT_DETAIL_READ_ALL';
export const TEST_RESULT_DETAIL_CREATE = 'TEST_RESULT_DETAIL_CREATE';
export const TEST_RESULT_DETAIL_READ = 'TEST_RESULT_DETAIL_READ';
export const TEST_RESULT_DETAIL_UPDATE = 'TEST_RESULT_DETAIL_UPDATE';
export const TEST_RESULT_DETAIL_DELETE = 'TEST_RESULT_DETAIL_DELETE';
export const TEST_RESULT_DETAIL_LOADING = 'TEST_RESULT_DETAIL_LOADING';
export const TEST_RESULT_DETAIL_ERROR = 'TEST_RESULT_DETAIL_ERROR';

export const NAVBAR_LOADPAGENAME = 'NAVBAR_LOADPAGENAME';
export const NAVBAR_LOADPAGEICON = 'NAVBAR_LOADPAGEICON';
