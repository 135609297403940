import { ALERT_SET, ALERT_RESET } from '../actions/types';

const initialState = [];

/*************************************************************************************
 * Reduces function for handling alert related actions.
 *
 *************************************************************************************/
function alertReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ALERT_SET:
      return [...state, payload];
    case ALERT_RESET:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
}

export default alertReducer;
