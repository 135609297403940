import api from '../utils/api';
import { setAlert } from './alert';
import formatAlert from '../utils/alert';
import {
  TEST_RESULT_CREATE,
  TEST_RESULT_UPDATE,
  TEST_RESULT_DELETE,
  TEST_RESULT_READ_ALL,
  TEST_RESULTS_WITH_FILTER_READ,
  TEST_RESULT_SELECT,
  TEST_RESULT_RESET,
  TEST_RESULT_RESET_ALL,
  TEST_RESULT_SET_FILTER,
  TEST_RESULT_LOADING,
  TEST_RESULT_ERROR,
} from './types';

/*************************************************************************************
 * GET TEST RESULTS
 *
 * Retrieves all testResults from the server and dispatches an action.
 *
 *************************************************************************************/
export const getTestResults = () => async (dispatch) => {
  console.log('ACTION: PRODUCT: getTestResults: IN --->');

  try {
    // Get testResults
    dispatch({ type: TEST_RESULT_LOADING });
    const res = await api.get(process.env.REACT_APP_API_URL + '/testResult');

    // Success
    dispatch({
      type: TEST_RESULT_READ_ALL,
      payload: res.data,
    });
  } catch (err) {
    // Error
    console.log(`ACTION: PRODUCT: getTestResults: ERROR`);
    dispatch({ type: TEST_RESULT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: PRODUCT: getTestResults: OUT <---');
};

/*************************************************************************************
 * GET TEST RESULTS WITH FILTER
 *
 * Retrieves testResults with filter from the server and dispatches an action.
 *
 *************************************************************************************/
export const getTestResultsWithFilter = (filterData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: getTestResultsWithFilter IN --->');

  try {
    // Get testResults with filter
    dispatch({ type: TEST_RESULT_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/testResult/filter', filterData);

    // Success
    dispatch({
      type: TEST_RESULTS_WITH_FILTER_READ,
      payload: { testResults: res.data.testResults, count: res.data.count },
    });

    console.log('ACTION: TEST RESULT: getTestResultsWithFilter OUT --->');
    return res.data.testResults; // Return testResults
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT: getTestResultsWithFilter: ERROR`);
    dispatch({ type: TEST_RESULT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT: getTestResultsWithFilter OUT --->');
  return null;
};

/*************************************************************************************
 * CREATE TEST RESULT
 *
 * Creates a new testResult using the provided form data.
 *
 *************************************************************************************/
export const createTestResult = (formData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: createTestResult: IN --->');

  try {
    // Create testResult
    dispatch({ type: TEST_RESULT_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + '/testResult', formData);

    // Success
    dispatch({
      type: TEST_RESULT_CREATE,
      payload: res.data,
    });

    console.log('ACTION: TEST RESULT: createTestResult: OUT <---');
    return res.data; // Return the created testResult
  } catch (err) {
    console.log('     Error');
    const errors = err.response.data.errors;
    if (errors) {
      // Error
      console.log(`ACTION: TEST RESULT: createProduct: ERROR`);
      dispatch({ type: TEST_RESULT_ERROR });
      dispatch(setAlert(formatAlert(err), 'danger'));
    }
  }

  console.log('ACTION: TEST RESULT: createTestResult: OUT <---');
  return null;
};

/*************************************************************************************
 * UPDATE TEST RESULT
 *
 * Updates a testResult using the provided ID and form data.
 *
 *************************************************************************************/
export const updateTestResult = (id, formData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: updateTestResult: IN --->');

  try {
    // Update testResult
    dispatch({ type: TEST_RESULT_LOADING });
    const res = await api.post(process.env.REACT_APP_API_URL + `/testResult/${id}`, formData);

    // Success
    dispatch({
      type: TEST_RESULT_UPDATE,
      payload: res.data,
    });

    console.log('ACTION: TEST RESULT: updateTestResult: OUT <---');
    return res.data; // Return the updated testResult
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT: updateTestResult: ERROR`);
    dispatch({ type: TEST_RESULT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT: updateTestResult: OUT <---');
  return null;
};

/*************************************************************************************
 * DELETE TEST RESULT
 *
 * Deletes a testResult with the specified ID.
 *
 *************************************************************************************/
export const deleteTestResult = (id) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: deleteTestResult: IN --->');

  try {
    // Delete testResult
    dispatch({ type: TEST_RESULT_LOADING });
    await api.delete(process.env.REACT_APP_API_URL + `/testResult/${id}`);

    // Success
    dispatch({
      type: TEST_RESULT_DELETE,
      payload: id,
    });
  } catch (err) {
    // Error
    console.log(`ACTION: TEST RESULT: deleteTestResult: ERROR`);
    dispatch({ type: TEST_RESULT_ERROR });
    dispatch(setAlert(formatAlert(err), 'danger'));
  }

  console.log('ACTION: TEST RESULT: deleteTestResult: OUT <---');
};

/*************************************************************************************
 * SELECT TEST RESULT
 *
 * Selects a testResult and dispatches an action to update the state.
 *
 *************************************************************************************/
export const selectTestResult = (testResult) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: selectTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_SELECT,
    payload: testResult,
  });

  console.log('ACTION: TEST RESULT: selectTestResult: OUT <---');
};

/*************************************************************************************
 * RESET TEST RESULT
 *
 * Resets the testResult and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetTestResult = () => async (dispatch) => {
  console.log('ACTION: TEST RESULT: resetTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_RESET,
    payload: null,
  });

  console.log('ACTION: TEST RESULT: resetTestResult: OUT <---');
};

/*************************************************************************************
 * RESET ALL TEST RESULT
 *
 * Resets all testResult and dispatches an action to update the state.
 *
 *************************************************************************************/
export const resetAllTestResult = () => async (dispatch) => {
  console.log('ACTION: TEST RESULT: resetAllTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_RESET_ALL,
    payload: null,
  });

  console.log('ACTION: TEST RESULT: resetAllTestResult: OUT <---');
};

/*************************************************************************************
 * SET TEST RESULT FILTER
 *
 * Sets the testResult filter and dispatches an action to update the state.
 *
 *************************************************************************************/
export const setTestResultFilter = (filterData) => async (dispatch) => {
  console.log('ACTION: TEST RESULT: resetAllTestResult: IN --->');

  dispatch({
    type: TEST_RESULT_SET_FILTER,
    payload: filterData,
  });

  console.log('ACTION: TEST RESULT: resetAllTestResult: OUT <---');
};
