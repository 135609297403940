import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
import Products from './components/products/Products';
import ProductForm from './components/products/ProductForm';
import TestResults from './components/testResults/TestResults';
import TestResultForm from './components/testResults/TestResultForm';
import TestResultsChartWeek from './components/testResults/TestResultsChartWeek';
import TestResultDetails from './components/testResultDetails/TestResultDetails';
import TestResultDetailForm from './components/testResultDetails/TestResultDetailForm';

import PrivateRoute from './components/routing/PrivateRoute';
import { AUTH_LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

/*************************************************************************************
 * React app running on port 3000 as default.
 *
 *************************************************************************************/
const App = () => {
  // When the component mounts
  // Check if there is a token in localStorage
  // If there is a token set axios headers for all requests
  // Try to fetch a user, if no token or invalid token we will get a 401 response from our API
  // If there is no token, logout the user
  useEffect(() => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    store.dispatch(loadUser());

    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: AUTH_LOGOUT });
    });
  }, []);

  // Returns JSX element
  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='register' element={<Register />} />
          <Route path='login' element={<Login />} />
          <Route path='products' element={<PrivateRoute component={Products} />} />
          <Route path='productForm' element={<PrivateRoute component={ProductForm} />} />
          <Route path='testResults' element={<PrivateRoute component={TestResults} />} />
          <Route path='testResultForm' element={<PrivateRoute component={TestResultForm} />} />
          <Route
            path='testResult/chart'
            element={<PrivateRoute component={TestResultsChartWeek} />}
          />
          <Route
            path='testResultDetails'
            element={<PrivateRoute component={TestResultDetails} />}
          />
          <Route
            path='testResultDetailForm'
            element={<PrivateRoute component={TestResultDetailForm} />}
          />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
