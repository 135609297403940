import { combineReducers } from 'redux';

// Import Reducers
import alert from './alert';
import auth from './auth';
import product from './product';
import testResult from './testResult';
import testResultDetail from './testResultDetail';

import navbar from './navbar';

// Root Reducer
export default combineReducers({
  alert,
  auth,
  product,
  testResult,
  testResultDetail,
  navbar,
});
