import {
  TEST_RESULT_DETAIL_CREATE,
  TEST_RESULT_DETAIL_READ,
  TEST_RESULT_DETAIL_UPDATE,
  TEST_RESULT_DETAIL_DELETE,
  TEST_RESULT_DETAIL_READ_ALL,
  TEST_RESULT_DETAIL_SELECT,
  TEST_RESULT_DETAIL_RESET,
  TEST_RESULT_DETAIL_RESET_ALL,
  TEST_RESULT_DETAIL_LOADING,
  TEST_RESULT_DETAIL_ERROR,
} from '../actions/types';

const initialState = {
  testResultDetails: [],
  testResultDetail: null,
  loading: false,
};

/*************************************************************************************
 * Reducer function for the testResultDetail state.
 *
 *************************************************************************************/
function testResultDetail(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TEST_RESULT_DETAIL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TEST_RESULT_DETAIL_READ_ALL:
      return {
        ...state,
        testResultDetails: payload,
        loading: false,
      };
    case TEST_RESULT_DETAIL_UPDATE:
    case TEST_RESULT_DETAIL_READ:
    case TEST_RESULT_DETAIL_CREATE:
    case TEST_RESULT_DETAIL_SELECT:
      return {
        ...state,
        testResultDetail: payload,
        loading: false,
      };
    // Remove the testResultDetail deleted from state
    case TEST_RESULT_DETAIL_DELETE:
      return {
        ...state,
        testResultDetails: state.testResultDetails.filter(
          (testResultDetail) => testResultDetail.id !== payload
        ),
        loading: false,
      };
    case TEST_RESULT_DETAIL_RESET:
      return {
        ...state,
        testResultDetail: null,
        loading: false,
      };
    case TEST_RESULT_DETAIL_RESET_ALL:
      return {
        ...state,
        testResultDetails: [],
        loading: false,
      };
    case TEST_RESULT_DETAIL_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default testResultDetail;
