import React, { Fragment } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';
import { resetTestResult } from '../../actions/testResult';

/*************************************************************************************
 * Renders a navigation bar component.
 *
 *************************************************************************************/
const Navbar = ({
  logout,
  auth: { loading, isAuthenticated },
  navbar: { pageName, pageIcon },
  testResult: { testResults, loading: testResultsLoading },
  resetTestResult,
}) => {
  const navigate = useNavigate();

  /*************************************************************************************
   * Handles the logout event.
   *
   *************************************************************************************/
  const onClickLogout = async (e) => {
    await logout();

    if (isAuthenticated) {
      navigate('/login');
    }
  };

  const onClickDashboard = (e) => {
    resetTestResult();

    navigate('testResult/chart');
  };

  const onClickTestResults = (e) => {
    resetTestResult();

    navigate('testResults');
  };

  const authLinks = (
    <ul>
      <li>
        <a onClick={(e) => onClickDashboard(e)} href='#!'>
          <i className='fas fa-area-chart' />
          <span className='hide-sm'>Dashboard</span>
        </a>
      </li>
      <li>
        <a onClick={(e) => onClickTestResults(e)} href='#!'>
          <i className='fas fa-check-double' />
          <span className='hide-sm'>Test Results</span>
        </a>
      </li>
      <li>
        <Link to='/products'>
          <i className='fa-solid fa-microchip' />
          <span className='hide-sm'>Products</span>
        </Link>
      </li>
      <li>
        <a onClick={(e) => onClickLogout(e)} href='#!'>
          <i className='fas fa-sign-out-alt' />
          <span className='hide-sm'>Logout</span>
        </a>
      </li>
    </ul>
  );

  const guestLinks = <ul></ul>;

  return (
    <nav className='navbar'>
      <nav className='navbartitle'>
        <h1>
          {'CDVI Cloud'}
          <i className='fas fa-solid fa-chevron-right' />{' '}
          <i className={'fas fa-solid ' + pageIcon} /> {pageName}
        </h1>
      </nav>
      <nav className='navbarmenu'>
        {!loading && <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>}
      </nav>
    </nav>
  );
};

Navbar.propTypes = {
  resetTestResult: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired, // auth action
  auth: PropTypes.object.isRequired, // auth reducer
  navbar: PropTypes.object.isRequired, // navbar reducer
  pageName: PropTypes.string.isRequired,
  pageIcon: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
  auth: state.auth,
  navbar: state.navbar,
  pageName: state.navbar.pageName, // navbar reducer
  pageIcon: state.navbar.pageIcon,
});

export default connect(mapStateToProps, { logout, resetTestResult })(Navbar);
