import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProduct, updateProduct } from '../../actions/product';
import { useNavigate } from 'react-router-dom';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';

/*************************************************************************************
 * Renders a form for creating or updating a product.
 *
 *************************************************************************************/
const ProductForm = ({
  createProduct,
  updateProduct,
  pageName,
  loadPageName,
  loadPageIcon,
  product: { product, loading },
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: product ? product.name : '',
    prefix: product ? product.prefix : '',
  });

  const { name, prefix } = formData;

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Products > Product') {
      loadPageName('Products > Product');
      loadPageIcon('fa-solid fa-microchip');
    }
  }, [product, formData]);

  // HANDLER: FIELD CHANGE
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // HANDLER: CLICK SAVE
  const onClickSave = (e) => {
    e.preventDefault();

    // Create or update the product
    if (!product) {
      createProduct(formData).then((data) => {
        if (data) {
          navigate('/products');
        }
      });
    } else {
      updateProduct(product.id, formData).then((data) => {
        if (data) {
          navigate('/products');
        }
      });
    }
  };

  // HANDLER: CLICK BACK
  const onClickBack = () => {
    navigate('/products');
  };

  // RENDER
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <section className='container'>
            {/*--- HEADER ---*/}
            <div className='container-header'>
              {/*--- TITLE ---*/}
              <div className='container-title'>
                <i className='fa-solid fa-microchip fa-xl text-primary' />
                <h1 className='text-primary'>Product</h1>
              </div>
              {/*--- ACTIONS ---*/}
              <div className='container-action'>
                {/*--- BACK --->*/}
                <button className={'btn btn-primary'} onClick={() => onClickBack()}>
                  <i className='fas fa-arrow-left' />
                </button>
              </div>
            </div>

            {/*--- BODY ---*/}
            <section className='container-body'>
              <form className='form' onSubmit={(e) => onClickSave(e)}>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Name'
                    name='name'
                    value={name}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <div className='form-group'>
                  <input
                    type='text'
                    placeholder='Prefix'
                    name='prefix'
                    value={prefix}
                    onChange={(e) => onChange(e)}
                  />
                </div>
                <input type='submit' className='btn btn-primary' value='Save' />
              </form>
            </section>
          </section>
        </Fragment>
      )}
    </section>
  );
};

ProductForm.propTypes = {
  createProduct: PropTypes.func.isRequired,
  updateProduct: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  product: state.product,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  createProduct,
  updateProduct,
  loadPageName,
  loadPageIcon,
})(ProductForm);
