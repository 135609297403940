import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getTestResultDetails,
  deleteTestResultDetail,
  resetTestResultDetail,
  resetAllTestResultDetail,
  selectTestResultDetail,
} from '../../actions/testResultDetail';
import { loadPageName, loadPageIcon } from '../../actions/navbar';
import Spinner from '../layout/Spinner';
import 'react-datepicker/dist/react-datepicker.css';

/*************************************************************************************
 * Renders the TestResultDetails component.
 *
 *************************************************************************************/
const TestResultDetails = ({
  getTestResultDetails,
  deleteTestResult,
  resetTestResultDetail,
  resetAllTestResultDetail,
  selectTestResultDetail,
  loadPageName,
  loadPageIcon,
  testResult: { testResult },
  testResultDetail: { testResultDetails, testResultDetail, loading },
  pageName,
}) => {
  // INITIALIZATIONS AND STATES
  const navigate = useNavigate();

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  // USE EFFECT
  useEffect(() => {
    if (pageName !== 'Test Results > Test Result Details') {
      loadPageName('Test Results > Test Result Details');
      loadPageIcon('fa-check-double');
    }

    if (testResult) {
      getTestResultDetails(testResult.id);
    }
  }, []);

  // HANDLER: CLICK CHECKBOX
  const handleCheckboxChange = (e, testResultDetailId) => {
    if (e.target.checked) {
      setSelectedCheckboxes([...selectedCheckboxes, testResultDetailId]);
    } else {
      setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== testResultDetailId));
    }
  };

  // HANDLER: CLICK CREATE
  const onClickCreate = () => {
    resetTestResultDetail();
    navigate(`/testResultDetailForm`);
  };

  // HANDLER: CLICK UPDATE
  const onClickUpdate = (id) => {
    // Set the selected testResultDetail
    const selectedTestResultDetail = testResultDetails.find(
      (testResultDetail) => testResultDetail.id === Number(id)
    );
    if (selectedTestResultDetail) {
      selectTestResultDetail(selectedTestResultDetail);
    }

    navigate(`/testResultDetailForm`);
  };

  // HANDLER: CLICK DELETE
  const onClickDelete = () => {
    // Delete each selected testResultDetail by its id
    selectedCheckboxes.forEach((id) => {
      deleteTestResultDetail(id);
    });

    // Clear the list of selected checkboxes
    setSelectedCheckboxes([]);

    // Fetch the updated list of products
    getTestResultDetails();
  };

  // HANDLER: CLICK RELOAD
  const onClickReload = () => {
    setSelectedCheckboxes([]);
    resetTestResultDetail();
    if (testResult) {
      getTestResultDetails(testResult.id);
    }
  };

  // HANDLERS: CLICK BACK
  const onClickBack = () => {
    navigate('/testResults');
  };

  // RENDER
  return (
    <section>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <section className='container'>
            {/*--- HEADER ---*/}
            <div className='container-header'>
              {/*--- TITLE ---*/}
              <div className='container-title'>
                <i className='fas fa-check-double fa-xl text-primary' />
                <h1 className='text-primary'>Test Result Details</h1>
              </div>
              {/*--- ACTIONS --->*/}
              <div className='container-action'>
                {/*--- CREATE (ADMIN) --->
                <button
                  className={
                    selectedCheckboxes.length < 1
                      ? 'btn btn-primary'
                      : 'btn btn-primary btn-disabled disabled'
                  }
                  onClick={() => onClickCreate()}
                >
                  <i className='fas fa-plus ' />
                </button>
                */}
                {/*--- EDIT (ADMIN) --->
                <button
                  className={
                    selectedCheckboxes.length === 1
                      ? 'btn btn-primary'
                      : 'btn btn-primary btn-disabled disabled'
                  }
                  onClick={() => onClickUpdate(selectedCheckboxes)}
                >
                  <i className='fas fa-pencil ' />
                </button>
                */}
                {/*--- DELETE (ADMIN) --->
                <button
                  className={
                    selectedCheckboxes.length > 0
                      ? 'btn btn-danger'
                      : 'btn btn-danger btn-disabled disabled'
                  }
                  onClick={() => onClickDelete()}
                >
                  <i className='fas fa-trash' />
                </button>
                */}
                {/*--- RELOAD --->*/}
                <button className={'btn btn-primary'} onClick={() => onClickReload()}>
                  <i className='fas fa-rotate-right ' />
                </button>

                {/*--- BACK --->*/}
                <button className={'btn btn-primary'} onClick={() => onClickBack()}>
                  <i className='fas fa-arrow-left' />
                </button>
              </div>
            </div>

            {/*--- BODY ---*/}
            <section className='container-body'>
              <div className='table'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>&#x2713;</th>
                      <th>Test Type</th>
                      <th>Test Type Description</th>
                      <th>Status</th>
                      <th>Elapse Time</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {testResultDetails.map((testResultDetail) => (
                      <tr key={testResultDetail.id}>
                        <td>
                          {/*--- TEST RESULT DETAIL SELECTED (ADMIN) --->
                          <input
                            type='checkbox'
                            id={testResultDetail.id}
                            onChange={(e) => handleCheckboxChange(e, testResultDetail.id)}
                          />
                          */}
                        </td>

                        <td>{testResultDetail.TestType}</td>
                        <td>{testResultDetail.TestTypeDescription}</td>
                        <td>{testResultDetail.Status}</td>
                        <td>{testResultDetail.ElapseTime}</td>
                        <td>{testResultDetail.Details}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </section>
        </Fragment>
      )}
    </section>
  );
};

TestResultDetails.propTypes = {
  getTestResultDetails: PropTypes.func.isRequired,
  deleteTestResultDetail: PropTypes.func.isRequired,
  resetTestResultDetail: PropTypes.func.isRequired,
  resetAllTestResultDetail: PropTypes.func.isRequired,
  selectTestResultDetail: PropTypes.func.isRequired,
  loadPageName: PropTypes.func.isRequired,
  loadPageIcon: PropTypes.func.isRequired,
  testResult: PropTypes.object.isRequired,
  testResultDetail: PropTypes.object.isRequired,
  pageName: PropTypes.string,
};

const mapStateToProps = (state) => ({
  testResult: state.testResult,
  testResultDetail: state.testResultDetail,
  pageName: state.navbar.pageName,
});

export default connect(mapStateToProps, {
  getTestResultDetails,
  deleteTestResultDetail,
  resetTestResultDetail,
  resetAllTestResultDetail,
  selectTestResultDetail,
  loadPageName,
  loadPageIcon,
})(TestResultDetails);
