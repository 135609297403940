import React, { Fragment } from 'react';
import { BeatLoader } from 'react-spinners';

/*************************************************************************************
 *
 *  <img src={spinner} style={{ width: '200px', margin: 'auto', display: 'block' }} alt='Loading...' />
 *************************************************************************************/
const Spinner = () => (
  <section className='spinner-wrapper'>
    <BeatLoader color={'#666666'} loading={true} size={15} speadmultiplier={1.5} />
  </section>
);

export default Spinner;
