import {
  PRODUCT_CREATE,
  PRODUCT_READ,
  PRODUCT_UPDATE,
  PRODUCT_DELETE,
  PRODUCT_READ_ALL,
  PRODUCT_SELECT,
  PRODUCT_RESET,
  PRODUCT_RESET_ALL,
  PRODUCT_LOADING,
  PRODUCT_ERROR,
} from '../actions/types';

const initialState = {
  products: [],
  product: null,
  loading: false,
};

/*************************************************************************************
 * Reducer function for the product state.
 *
 *************************************************************************************/
function product(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PRODUCT_READ_ALL:
      return {
        ...state,
        products: payload,
        loading: false,
      };
    case PRODUCT_UPDATE:
    case PRODUCT_READ:
    case PRODUCT_CREATE:
    case PRODUCT_SELECT:
      return {
        ...state,
        product: payload,
        loading: false,
      };
    // Remove the product deleted from state
    case PRODUCT_DELETE:
      return {
        ...state,
        products: state.products.filter((product) => product.id !== payload),
        loading: false,
      };
    case PRODUCT_RESET:
      return {
        ...state,
        product: null,
        loading: false,
      };
    case PRODUCT_RESET_ALL:
      return {
        ...state,
        products: [],
        loading: false,
      };
    case PRODUCT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default product;
