import {
  TEST_RESULT_CREATE,
  TEST_RESULT_READ,
  TEST_RESULT_UPDATE,
  TEST_RESULT_DELETE,
  TEST_RESULT_READ_ALL,
  TEST_RESULTS_WITH_FILTER_READ,
  TEST_RESULT_SELECT,
  TEST_RESULT_RESET,
  TEST_RESULT_RESET_ALL,
  TEST_RESULT_SET_FILTER,
  TEST_RESULT_LOADING,
  TEST_RESULT_ERROR,
} from '../actions/types';

const initialState = {
  testResults: [],
  testResult: null,
  count: 0,
  loading: false,
  filterSerialNumber: '',
  filterStartDateTime: '',
  filterEndDateTime: '',
  filterOverallTestStatus: 'All',
  filterProductId: 'All',
  filterLimit: 10,
  filterSelectedColumn: 'StartTime',
  filterPageNumber: 1,
};

/*************************************************************************************
 * Reducer function for the testResult state.
 *
 *************************************************************************************/
function testResult(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case TEST_RESULT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case TEST_RESULT_READ_ALL:
      return {
        ...state,
        testResults: payload,
        loading: false,
      };
    case TEST_RESULTS_WITH_FILTER_READ:
      return {
        ...state,
        testResults: payload.testResults,
        count: payload.count,
        loading: false,
      };
    case TEST_RESULT_UPDATE:
    case TEST_RESULT_READ:
    case TEST_RESULT_CREATE:
    case TEST_RESULT_SELECT:
      return {
        ...state,
        testResult: payload,
        loading: false,
      };
    // Remove the testResult deleted from state
    case TEST_RESULT_DELETE:
      return {
        ...state,
        testResults: state.testResults.filter((testResult) => testResult.id !== payload),
        loading: false,
      };
    case TEST_RESULT_RESET:
      return {
        ...state,
        testResult: null,
        loading: false,
      };
    case TEST_RESULT_RESET_ALL:
      return {
        ...state,
        testResults: [],
        count: 0,
        loading: false,
      };
    case TEST_RESULT_SET_FILTER:
      return {
        ...state,
        filterSerialNumber: payload.SerialNumber,
        filterStartDateTime: payload.StartDateTime,
        filterEndDateTime: payload.EndDateTime,
        filterOverallTestStatus: payload.OverallTestStatus,
        filterProductId: payload.ProductId,
        filterLimit: payload.Limit,
        filterSelectedColumn: payload.SelectedColumn,
        filterPageNumber: payload.PageNumber,
        loading: false,
      };
    case TEST_RESULT_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default testResult;
